import { teal } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';

import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import WriteIcon from "@mui/icons-material/Create";
import SendIcon from "@mui/icons-material/Send";

//import HomeIcon from "@mui/icons-material/Home";
//import { blue, green, grey, red } from "@mui/material/colors";

import get from "lodash/get";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { RootState } from "../reducers";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";



import {
	createMessage,
	getMessages,
	updateMessage
} from "../services/api";


import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    Typography,
    Button,
    Paper,
    TextField,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    FormHelperText
  } from '@mui/material';


const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		padding: theme.spacing(1),
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/
		width: "100%",
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: teal[600],
		"&:hover": {
			backgroundColor: "#46a9db",
		},
	},
	stat_card: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
	inline: {
		display: 'inline',
	},

    paper: {
		width: "100vw",
		height: "100vh",
		maxHeight: "1000px",
		display: "flex",
		flexDirection: "column",
		position: "relative",
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	  },
	  paper2: {
		width: "80vw",
		maxWidth: "500px",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		position: "relative"
	  },
	  container: {
		width: "100vw",
		height: "100vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	  },
	  messagesBody: {
		width: "calc( 100% - 20px )",
		margin: 10,
		overflowY: "scroll",
		height: "calc( 100% - 194px )"
	  }


}));

export function MessageWritePage() {
	const navigate = useNavigate();	
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [messages, setMessages] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [possibleRecipients, setPossibleRecipients] = React.useState([]);

	const currentUser = useSelector((state: RootState) => state.user);

    const [values, setValues] = React.useState({
        recipient: "",
        subject: "",
        message: "",
      });
    
    
      const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      };



	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};
	
	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {

				let data = await getMessages(0, 0);
				console.log(data);

				// get the possible recipients
				let _possibleRecipientsDict = {};
				for(let i = 0; i < data.results.length; i++){
					if(data.results[i].sender == currentUser.id){
						//_possibleRecipientsDict.push( { data.results[i].recipient, data.results[i].recipient});
					}else{
						_possibleRecipientsDict [ data.results[i].sender ] = data.results[i].sender_fullname ;
					}
				}
				//_possibleRecipients = [...new Set(_possibleRecipients)];
				// set possible recipients
				let _possibleRecipients = [];
				for (const [key, value] of Object.entries(_possibleRecipientsDict)) {
					_possibleRecipients.push( { key, value} );
				} 


				console.log("_possibleRecipients", _possibleRecipients);
				setPossibleRecipients(_possibleRecipients);



			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const possibleRecipientsListItems = possibleRecipients.map((item) =>

		<MenuItem value={item.key}>{item.value}</MenuItem>
	);

	const sendMessage = async () => {
		console.log("sendMessage");


		navigate(-1);

		let new_message_data = 
		{
			//"id": 133,
			"subject": values.subject,
			"body": values.message,
			"sender": currentUser.id,
			"recipient": values.recipient,
			"email": "",
			"parent": null,
			"thread": null,
			"sent_at": moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
			"read_at": null,
			"replied_at": null,
			"sender_archived": false,
			"recipient_archived": false,
			"sender_deleted_at": null,
			"recipient_deleted_at": null,
			"moderation_status": "a",
			"moderation_by": null,
			"moderation_date": moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
			"moderation_reason": ""
		}

		try{
			const new_message = await createMessage(
				new_message_data
			);
			console.log("new_message", new_message);
			console.log("messages", messages);
			
			//setMessages([ ...messages, new_message]);
			//setMessage(  t('profile_page.profile_saved') );
		}catch(e){
			console.log(e);
		}


		// let userValues = pick(values, ['first_name',  'last_name']);
		// let profileValues = pick(values, ['gender', 'language']);

		// let _currentUser = {...currentUser, ...userValues};
		// _currentUser.profile = {...currentUser.profile, ...profileValues};
		
		// // console.log(_currentUser);
		// // remove cards for now
		// _currentUser.cards = []			

		// try{
		// 	const updatedUser = await updateAccount(
		// 		_currentUser
		// 	);
		// 	authActions.setUser(updatedUser);
		// 	setMessage(  t('profile_page.profile_saved') );
		// }catch(e){
		// 	console.log(e);
		// }

		
		//authActions.setUser(currentUser);
	}


	if (loading) {
		return <Loading />;
	}





    const current_month = moment().format('MMMM');

	return (
		<div className={classes.container}>
            <Paper className={classes.paper}>
                <div>
                    <div>
                        <br/>
                    </div>
                    <FormControl sx={{ m: 0, minWidth: 100 }} required fullWidth>
                        <InputLabel id="recipient-select-label">{t('message_write_page.to')}</InputLabel> 
                        <Select
                            labelId='recipient-select-label'
                            label={t('message_write_page.to')}
                            id='recipient-select'
                            value={values.recipient}
                            name="recipient"
                            variant="outlined"
                            margin="dense"          
                            onChange={handleChange}

                        >
						{possibleRecipientsListItems}
                        </Select>
                        <FormHelperText>{t('message_write_page.to_hint')}</FormHelperText>
                    </FormControl>
                    
                    <FormControl sx={{ m: 0, minWidth: 100 }} required fullWidth>

                        <TextField

                            label={t('message_write_page.subject')}
                            id='subject-select'
                            value={values.subject}
                            name="subject"
                            variant="outlined"
                            margin="dense"          
                            onChange={handleChange}

                        >
                        </TextField>
                        <FormHelperText>{t('message_write_page.subject_hint')}</FormHelperText>
                    </FormControl>
                    <br/><br/>

                    <FormControl sx={{ m: 0, minWidth: 100 }} required fullWidth>

                        <TextField

                            label={t('message_write_page.body')}
                            id='message-select'
                            value={values.message}
                            name="message"
                            variant="outlined"
                            margin="dense"          
                            onChange={handleChange}
                            multiline
                            rows={4}

                        >
                        </TextField>
                        <FormHelperText>{t('message_write_page.body_hint')}</FormHelperText>
                    </FormControl>
                    <br/><br/>


                    <Button variant="contained" color="primary" onClick={() => sendMessage()}>
                        <SendIcon />  
                    </Button>
                </div>
            </Paper>                  
    	  </div>
	);

}
