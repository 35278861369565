import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Snackbar,
	TextareaAutosize,
	Typography,
	Box,
	Grid,
	
} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { Loading } from "../components";
import { RootState } from "../reducers";
import { getVisitActivityById, updateVisitActivity } from "../services/api";
import { IntegerSetting } from "../components/IntegerSetting";
import { FloatSetting } from "../components/FloatSetting";
import { TimeSetting } from "../components/TimeSetting";
import { StringSetting } from "../components/StringSetting";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from "@mui/material/IconButton";
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next';
import { min, toInteger } from "lodash";


import "moment/locale/fi"
import "moment/locale/de"
import "moment/locale/fr"
import "moment/locale/zh-cn"
import "moment/locale/zh-tw"
import moment from "moment";
import 'moment-duration-format';



const cdnAddress = "https://cdn01.hursmarttouch.com"; //"https://d3ldm7xf6jmsyw.cloudfront.net";

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 275,
		marginTop: "0rem",
		marginBottom: "1rem",
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	backButton: {
		margin: "1rem",
	},
	titleContainer: {
		display: "flex",
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		alignContent: "center",
		height: "42px",
		backgroundColor: "#607d8b",
		justifyContent: "center",
	},
	title: {
		fontSize: 14,
		fontWeight: "bold",
		color: "white",
	},
	media: {
		// height: 240,
		backgroundSize: "contain",
	},
	pos: {
		marginBottom: "0px",
	},
	statusButtons: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	statusButton: {
		borderRadius: 4,
		padding: "12px 18px",
		fontSize: "13px",
		marginBottom: "1rem",
	},
	notes: {
		width: "100%",
		fontSize: "16px",
		border: "1px solid #9E9E9E",
		borderRadius: 4,
		padding: "12px",
	},
	saveNotesButton: {
		margin: "0 auto",
	},
}));

function formatTime(seconds: number): string {

	const duration = moment.duration(seconds, 'seconds');
  
	if (seconds < 60) {
	  return duration.format('ss [sec]');
	} else if (seconds < 3600) {
	  if (seconds % 60 === 0) {
		if(seconds < 600){
			return duration.format('m [min]');
		}
		return duration.format('mm [min]');
	  }				
	  return duration.format('mm [min] ss [sec]');
	} else {
	  return duration.format('hh [hours] mm [min] ss [sec]');
	}
}

export function ActivityPage(props) {
	const navigate = useNavigate();
	const location = useLocation();	
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	let { activityId } = useParams<{ activityId: string }>();
	const [activity, setActivity] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [updatingActivity, setUpdatingActivity] = React.useState(false);
	const [message, setMessage] = React.useState(null);
	const [results, setResults] = React.useState(null);
	const [notes, setNotes] = React.useState("");
	const [showVideo, setShowVideo] = React.useState(false);

	const [lowerView, setLowerView] = React.useState('instructions');

	// get parameters from url
	const params = new URLSearchParams(location.search);
	const edit = params.get("e");

	
	// set language
	moment.locale(i18n.language);


	const handleNotesChange = (event: any) => {
		setNotes(event.target.value);
	};

	const handleShowVideoClick = () => {
		let _showVideo = !showVideo;
		setShowVideo(_showVideo);
	};


	const handleChangeLowerViewClick = () => {
		let _newLowerView;

		if(activityType !== 3){
			_newLowerView = lowerView == 'instructions' ? 'settings' : lowerView == 'settings' ? 'notes' : 'instructions';
		}else{
			if(edit){
				_newLowerView = lowerView == 'instructions' ? 'settings' : lowerView == 'settings' ? 'notes' : 'instructions';
			}else{
				_newLowerView = lowerView == 'instructions' ? 'notes' : 'instructions';
			}
		}
		
		setLowerView(_newLowerView);
	};


	const availableActivities = useSelector((state: RootState) => {
		return state.activities;
	});

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const activity = await getVisitActivityById(
					parseInt(activityId, 10)
				);

				setActivity(activity);
				
				//console.log("activity", activity);

				if (activity?.settings?.parameters) {
					if (
						activity.results &&
						typeof activity.results === "object"
					) {
						setResults(activity.results);
					} else {
						setResults(activity.settings);
					}
				} else {
					if (
						activity.results &&
						typeof activity.results === "object"
					) {
						//console.log("activity.results", activity.results);
						setResults(activity.results);
					} else {
						//console.log("activity.settings", activity.settings);
						//setResults(activity.settings);
					}
				}
				setNotes(activity.user_comments);
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
	}, [activityId]);

	if (loading || !activity) {
		return <Loading />;
	}

	const estimateStatus = () => {
		if (activityType === 7) {
			if (activity.settings.time > results.time) {
				return "U";
			}
			if (activity.settings.time < results.time) {
				return "O";
			}
			if (activity.settings.time === results.time) {
				return "F";
			}
		}
		if (activityType === 8 || activityType === 9) {
			if (activity.settings.performances > results.performances) {
				return "U";
			}
			if (activity.settings.performances < results.performances) {
				return "O";
			}
			if (activity.settings.performances === results.performances) {
				return "F";
			}
		}
		if (activityType === 56) {
			if (
				checkIfParametersAreUnderPerformed(
					activity.settings.parameters,
					results.parameters
				)
			) {
				return "U";
			}
			if (
				checkIfParametersAreOverPerformed(
					activity.settings.parameters,
					results.parameters
				)
			) {
				return "O";
			}
		}
		return "F";
	};

	const checkIfParametersAreUnderPerformed = (parameters, results) => {
		let underPerformed = false;
		for (const [idx, param] of parameters.entries()) {
			if (param.value > results[idx].value) {
				underPerformed = true;
				continue;
			}
		}
		return underPerformed;
	};

	const checkIfParametersAreOverPerformed = (parameters, results) => {
		let overPerformed = false;
		for (const [idx, param] of parameters.entries()) {
			if (param.value < results[idx].value) {
				overPerformed = true;
				continue;
			}
		}
		return overPerformed;
	};
	const onStatusUpdate = async (update: any, go_back: boolean = false, update_status: boolean = true ) => {
		setUpdatingActivity(true);
		activity.status = update_status ? estimateStatus() : activity.status;
		console.log("activity", activity);
		try {
			const updatedActivity = await updateVisitActivity(
				parseInt(activityId, 10),
				Object.assign({}, activity, update)
			);
			setActivity(updatedActivity);
			setMessage(t('activity_page.message.updated_successfully'));
			if (go_back) {
				navigate(-1);
			}
		} catch (e) {
			console.log(e);
		}
		setUpdatingActivity(false);
	};
	const setAsOverPerformed = () => {
		onStatusUpdate({
			status: "O",
		});
	};
	const setAsNotPerformed = () => {
		onStatusUpdate({
			status: "N",
		});
	};
	const setAsUnderPerformed = () => {
		onStatusUpdate({
			status: "U",
		});
	};
	const setAsFullyPerformed = () => {
		onStatusUpdate({
			status: "F",
		});
	};
	const saveNotes = () => {
		onStatusUpdate({
			user_comments: notes,
		}, false, false);
	};
	const setAsFullyPerformedAndReturn = () => {

		if([1, 3, 7, 8, 9, 56].includes(activityType) ){
			activity["results"] = results;
		}

		onStatusUpdate({
			status: "F",
		}, true);
	};

	const getStatusName = () => {
		switch (activity.status) {
			case "O":
				return t('statistics_page.over_performed'); 
			case "N":
				return t('statistics_page.not_performed');
			case "U":
				return t('statistics_page.under_performed');
			case "F":
				return t('statistics_page.fully_performed');
			case "S":
				return t('common.started');
		}
		return "Unknown";
	};

	const handleCloseMessage = () => {
		setMessage(null);
	};

	const activityDetails = availableActivities.find((availableActivity) => {
		return availableActivity.id === activity.activity;
	});
	const image =
		activityDetails.picture && activityDetails.picture.length > 1
			? activityDetails.picture
			: activityDetails.all_activity.picture;
	const imageFallback = activityDetails.all_activity.picture;
	const activityType = activityDetails.all_activity.activity_type;
	
	// use cdn for video
	const video = 
		activityDetails.video && activityDetails.video.length > 1
		? activityDetails.video
		: !!activityDetails.all_activity.video ? 
		 `${cdnAddress}/media/${activityDetails.all_activity.video}`
		 : null;	


	let parameters = [];


	if (activityType === 1) {
		// const handleChange = (value: number) => {
		// 	setResults({
		// 		time: value,
		// 	});
		// };
		// create parameters as a table of sets
		parameters = [
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 200 }} aria-label="simple table">
				<TableHead>
					<TableRow>
					<TableCell>Set</TableCell>
					<TableCell align="right">Load</TableCell>
					<TableCell align="right">Reps</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{activity.settings.sets?.map((set, index) => (
					<TableRow
						key={index+1}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row">
						{index+1} 
						</TableCell>
						<TableCell align="right">{activity.results?.sets?.[index]?.load ? 
							(`${(parseInt(activity.results.sets[index].load) / 1000).toFixed(1)} / ${(parseInt(set.load) / 1000).toFixed(1)}  kg`) : 
							`${(parseInt(set.load) / 1000).toFixed(1)}  kg`}
						</TableCell>
						<TableCell align="right">
						{activity.results?.sets?.[index]?.reps ? 
							(`${parseInt(activity.results.sets[index].reps)} / ${parseInt(set.reps)}`) : 
							`${parseInt(set.reps)}`}
						</TableCell>

					</TableRow>
					))}
				</TableBody>
				</Table>
			</TableContainer>
		];
	};

	// aerobic exercises
	console.log('results', results);
	if (results && results != "{}" && [2,13,15,16,17].includes(activityType) ) {

		// create table of poms
		parameters = [
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 200 }} aria-label="simple table">
				<TableHead>
					<TableRow>
					<TableCell sx={{ width: "10%" }}>#</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Load</TableCell>
					<TableCell align="right" sx={{ width: "60%" }}>Time</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>HR</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Rpm</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{activity.results?.poms?.map((pom, index) => (
					<TableRow
						key={index+1}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row">
						{index+1} 
						</TableCell>
						<TableCell align="right">
							{pom.res}&nbsp;{activity.settings?.units}
						</TableCell>
						<TableCell align="right">
						{formatTime(pom.time)}
						</TableCell>
						<TableCell align="right">
						{pom.HR}
						</TableCell>
						<TableCell align="right">
						{pom.rpm}
						</TableCell>
					</TableRow>	
					))}
				</TableBody>
				</Table>
			</TableContainer>
		];
	};

	if(!results && [2,13,15,16,17].includes(activityType) ){
		// create table of poms
		parameters = [
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 200 }} aria-label="simple table">
				<TableHead>
					<TableRow>
					<TableCell sx={{ width: "10%" }}>#</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Load</TableCell>
					<TableCell align="right" sx={{ width: "60%" }}>Time</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>HR</TableCell>
					<TableCell align="right" sx={{ width: "10%" }}>Rpm</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{activity.settings?.steps?.map((step, index) => (
					<TableRow
						key={index+1}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row">
						{index+1} 
						</TableCell>
						<TableCell align="right">
							{step.res}&nbsp;{activity.settings?.units}
						</TableCell>
						<TableCell align="right">
						{formatTime(step.time)}
						</TableCell>
						<TableCell align="right">
						{step.HR}
						</TableCell>
						<TableCell align="right">
						{step.rpm}
						</TableCell>
					</TableRow>	
					))}
				</TableBody>
				</Table>
			</TableContainer>
		];
	};


	if (results && activityType === 7) {
		const handleChange = (value: number) => {
			setResults({
				time: value,
			});
		};
		parameters = [
			<IntegerSetting
				key="Time"
				label="Time (minutes)"
				initialValue={results.time}
				update={handleChange}
			/>,
		];
	}
	if (results && activityType === 8) {
		const handleChange = (value: number) => {
			setResults({
				performances: value,
			});
		};
		parameters = [
			<IntegerSetting
				key="Performances"
				label="Performances"
				initialValue={results.performances}
				update={handleChange}
			/>,
		];
	}
	if (results && activityType === 9) {
		const handleChange = (value: number) => {
			setResults({
				performances: value,
			});
		};
		const load = (activity.settings.init_load / 1000).toFixed(0);
		const label = `Performances ${load} kg`;
		parameters = [
			<IntegerSetting
				key="Performances"
				label={label}
				initialValue={results.performances}
				update={handleChange}
			/>,
		];
	}
	if (activityType === 56 && Array.isArray(results.parameters)) {
		const handleChange = (idx: number) => {
			return (value: number) => {
				//console.log("Param", results.parameters);
				//console.log("Act settings", activity.settings);
				const updatesResultsParamaters = [...results.parameters];
				updatesResultsParamaters[idx].value = value;
			};
		};
		parameters = results.parameters.map((result, idx: number) => {
			if (result.type === "integer") {
				return (
					<IntegerSetting
						key={result.id}
						unit={result.unit}
						label={result.name}
						initialValue={result.value}
						update={handleChange(idx)}
					/>
				);
			}
			if (result.type === "float") {
				return (
					<FloatSetting
						key={result.id}
						unit={result.unit}
						label={result.name}
						initialValue={result.value}
						update={handleChange(idx)}
					/>
				);
			}
			if (result.type === "time") {
				return (
					<TimeSetting
						key={result.id}
						label={result.name}
						unit={result.unit}
						initialValue={result.value}
						update={handleChange(idx)}
					/>
				);
			}
			return (
				<StringSetting
					key={result.id}
					label={result.name}
					unit={result.unit}
					initialValue={result.value}
					update={handleChange(idx)}
				/>
			);
		});
	}

	const serverUrl = localStorage.getItem("server_url");

	// use cdn
	const imageUrl = `${image}`.startsWith('activity_images') ? `${cdnAddress}/media/${image}` : `${serverUrl}media/${image}`;
	
	console.log('imageUrl', imageUrl)
	console.log('image', `${serverUrl}media/${image}`)		
	const imageFallbackUrl = `${serverUrl}media/${imageFallback}`;
	const onMediaFallback = (event) => {
		event.target.src = imageFallbackUrl;
	};

	function SlideTransition(props) {
		return <Slide {...props} direction="up" />;
	}

	const BandOfButtons = (props: any) => {
		return (<Box
		sx={{
			bottom: 0,
			right: 0,
			width: '100%',
			bgcolor: 'rgba(0, 0, 0, 0.05)',
			color: 'white',

			justifyContent: "right",
			padding: "4px 4px",
			...props

		}}	
	> 
		<Grid container justifyContent="flex-end">
			{video && <IconButton
				onClick={() => { handleShowVideoClick(); } }
				size="small"
				sx={{
					bgcolor: 'rgba(0, 0, 0, 0.1)',
					padding: '4px 4px',
					maxHeight: '38px',
				    margin: '10px 2px'
				}}				
				>
				{ showVideo && <VideocamOffIcon style={{ fontSize: 28, justifyContent: "center" }} /> }
				{ !showVideo && <VideocamIcon style={{ fontSize: 28, justifyContent: "center" }} /> }
		
			</IconButton>
			}
			<IconButton
				aria-label="perform"

				onClick={() => { handleChangeLowerViewClick(); } }
				size="small"
				sx={{
					bgcolor: 'rgba(0, 0, 0, 0.1)',
					padding: '4px 4px',
					maxHeight: '38px',
				    margin: '10px 2px'

				}}				
				>
				<MoreHorizIcon style={{ fontSize: 28, justifyContent: "center" }} />
			</IconButton>
			
			{  (edit && [3, 7, 8, 9, 56].includes(activityDetails.all_activity.activity_type)) && <IconButton
				aria-label="perform"
				color="success"
				size="medium"
				onClick={() => { setAsFullyPerformedAndReturn(); } }
				sx={{
					bgcolor: 'rgba(0, 0, 0, 0.1)',
				}}				
				>
				<CheckCircleIcon style={{ fontSize: 38, justifyContent: "center" }} />
			</IconButton>
			}
		</Grid>
	</Box>)
	};

	const propagatedDescription = () => {
		/* all ____ and ___ in description shall be exchanged into iterated parameters values */
		let _description = activityDetails.description?.length > 0 ? activityDetails.description : activityDetails['all_activity'].description;
		let count = 0;
		_description = _description.replace(/____|___/g, (match) => {
			count++;
			if(results?.parameters?.length > count -1 ){
				return results.parameters[count-1].value;
			}
			return String("___");
		});
		
		return (<span style={{whiteSpace: 'pre-line'}}> {_description}</span>);
	}

	var this_player:any;

	function loop_video(){
		this_player.playing = true;
		this_player.seekTo(1, 'seconds');

		//console.log("loop");

	}

	function handleProgress(state){
		//console.log('onProgress', state);
		//console.log('this_player', this_player);
		// if(state['playedSeconds']>72){
			
		// 	this_player.seekTo(67, 'seconds');
		// 	this_player.playing = true;
		// }

	}

	function ref(player){
		this_player = player;
	}
	return (
		<div style={{ flex: 1 }}>
			<Box className={classes.root}>
				{/* <Box className={classes.titleContainer}>
					<Typography
						className={classes.title}
						color="textSecondary"
						gutterBottom
					>
						Activity Details
					</Typography>
				</Box> */}
				
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<div style={{ padding: "1vh 3vw 0vh 3vw" }}>
							<Typography variant="h5" component="h2">
								{activity?.activity_name}
							</Typography>
							<Typography className={classes.pos} color="textSecondary">
								{t('common.status')}: {getStatusName()}
							</Typography>

						</div>
					</Grid>
					<Grid item xs={12}>
					
						{video && showVideo &&
							<Box
								sx={{ position: 'relative' }}
								justifyContent="center"
								alignItems="center"


							>

								<div
									className="player-wrapper"
									style={{
										position: "relative",
										paddingTop: "80%",
									}}
								>
									<ReactPlayer
										id="video-player"
										ref={ref}
										url={video}
										controls={true}
										width="100%"
										height="100%"
										playing={true}
										muted={true}
										light = {false}
										style={{
											position: "absolute",
											top: 0,
											left: 0,
										}}
										config={{
											vimeo: {
											playerOptions: {
												playsinline: true,
											}
											}
										}}
										onEnded={loop_video}
										onProgress={handleProgress}
									/>
								</div>
								<BandOfButtons margin={'0px 0px 0px 0px'} position={'relative'}></BandOfButtons>
							</Box>
						}
					
					{!showVideo && <Box
							sx={{ position: 'relative' }}
							justifyContent="center"
							alignItems="center"
							display="flex"

						>
						<CardMedia 
							component="img"
							className={classes.media}
							image={imageUrl}
							title={activity.activity_name}
							onError={onMediaFallback}
							sx={{ position: 'relative', maxWidth: "50vh" }}

						/>

						<BandOfButtons margin={'0px 0px 0px 0px'} position={'absolute'}></BandOfButtons>
					</Box>
					}
					</Grid>

					{ lowerView == 'instructions' && <Grid item xs={12}>
						<div style={{ padding: "1vh 3vw 4vh 3vw" }}>
						
						{activity.instruction && 
						<Typography component="p">
							<i>{activity.instruction}</i>
						</Typography>						
						}
						<Typography variant="h6" component="h6">
							{t('activity_page.instruction')}
						</Typography>						
						<Typography component="p" >
						     {propagatedDescription()}
						</Typography>
						</div>													
					</Grid>
					}




				</Grid>



			</Box>



			{lowerView == 'settings' && activityType !== 3 ? (
				<Card className={classes.root} variant="outlined">
					<Box className={classes.titleContainer}>
						<Typography
							className={classes.title}
							color="textSecondary"
							gutterBottom
						>
							{ (activity.results && activity.results != "{}") ? t('activity_page.results') : t('settings_page.title') }

						</Typography>
					</Box>
					
						<CardContent sx={{ opacity: !edit ? 0.5 : 1, pointerEvents:!edit ? "none" : "auto"}} >{parameters}</CardContent>
					
				</Card>
			) : (
				""
			)}

			{ lowerView == 'settings' && <Grid item xs={12}>
				{(edit &&  [3, 7, 8, 9, 56].includes(activityDetails.all_activity.activity_type)) && <Card  variant="outlined">
					<Box className={classes.titleContainer}>
						<Typography
							className={classes.title}
							color="textSecondary"
							gutterBottom
						>
							{t('activity_page.save_as')}
						</Typography>
					</Box>
				
					<CardContent>
						<div className={classes.statusButtons}>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsOverPerformed}
								style={{
									backgroundColor: "#03a9f4",
									color: "white",
								}}
							>
								{t('statistics_page.over_performed')}
							</Button>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsNotPerformed}
								style={{
									backgroundColor: "#9e9e9e",
									color: "white",
								}}
							>
								{t('statistics_page.not_performed')}
							</Button>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsUnderPerformed}
								style={{
									backgroundColor: "#f44336",
									color: "white",
								}}
							>
								{t('statistics_page.under_performed')}
							</Button>
							<Button
								variant="outlined"
								className={classes.statusButton}
								onClick={setAsFullyPerformed}
								style={{
									backgroundColor: "#8bc34a",
									color: "white",
								}}
							>
								{t('statistics_page.fully_performed')}
							</Button>
						</div>
					</CardContent>
				
				</Card>
			}
			</Grid>
			}


			{ lowerView == 'notes' && <Card className={classes.root} variant="outlined">
				<Box className={classes.titleContainer}>
					<Typography
						className={classes.title}
						color="textSecondary"
						gutterBottom
					>
						{t('activity_page.notes')}
					</Typography>
				</Box>
				
					<CardContent sx={{p: 1.5}}>
						<TextareaAutosize
							aria-label="minimum height"
							minRows={5}
							onChange={handleNotesChange}
							value={notes}
							placeholder={t('activity_page.type_your_notes_here')}
							className={classes.notes}
						/>
					</CardContent>
					<CardActions>
						<Button
							color="primary"
							variant="contained"
							onClick={saveNotes}
							className={classes.saveNotesButton}
							
						>
							{t('common.save')}
						</Button>
					</CardActions>
			</Card>
			}
			<Box sx={{ margin: '0px 0px 50px 0px' }}></Box>		
			{message && <Snackbar
				open={!!message}
				autoHideDuration={4000}
				onClose={handleCloseMessage}
				TransitionComponent={SlideTransition}
				sx={{ bottom: { xs: 60, sm: 0 } }}
			>
				<Alert severity="success">
					{message}
				</Alert>
			</Snackbar>
			}
		</div>
	);
}
