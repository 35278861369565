import { teal } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import WriteIcon from "@mui/icons-material/Create";

//import HomeIcon from "@mui/icons-material/Home";
//import { blue, green, grey, red } from "@mui/material/colors";

import get from "lodash/get";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { RootState } from "../reducers";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

import { Paper } from '@mui/material';
import { TextInput } from "../components/Messages/TextInput";
import { MessageLeft } from "../components/Messages/Messages";
import { MessageRight } from "../components/Messages/Messages";

import {
	createMessage,
	getMessages,
	updateMessage
} from "../services/api";


import WeeklyGoal from "../components/Settings/WeeklyGoal";

const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		padding: theme.spacing(1),
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/
		width: "100%",
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: teal[600],
		"&:hover": {
			backgroundColor: "#46a9db",
		},
	},
	stat_card: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
	inline: {
		display: 'inline',
	},

    paper: {
		width: "100vw",
		height: "84vh",
		maxWidth: "600px",
		// maxHeight: "900px",
		display: "flex",
		//alignItems: "center",
		flexDirection: "column",
		position: "relative"
	  },
	  paper2: {
		width: "80vw",
		maxWidth: "500px",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		position: "relative"
	  },
	  container: {
		width: "100vw",
		//height: "100vh",
		display: "flex",
		//alignItems: "center",
		justifyContent: "center"
	  },
	  messagesBody: {
		width: "calc( 100% - 20px )",
		margin: 10,
		overflowY: "scroll",
		height: "calc( 100% - 86px )"
	  },

}));

export function MessagePage() {
	const navigate = useNavigate();	
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [messages, setMessages] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [lastVisit, setLastVisit] = React.useState(null);
	let { id } = useParams<{ id: string }>();

	const currentUser = useSelector((state: RootState) => state.user);
	const messagesEndRef = useRef(null)

	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {

				// get id from url
				// const url = window.location.href;
				// const url_split = url.split('/');
				// const id = url_split[url_split.length - 2];
				// console.log("id", id);

				// get parameter 't' from url
				const params = new URLSearchParams(window.location.search);
				const t = params.get('t');

				let data = await getMessages(0, parseInt(id) );
				//console.log(data);

				// get thread id from first message
				const thread =  data['results'][0]['thread'];
				//console.log("thread", thread);

				if(thread != null){
					data = await getMessages(parseInt(thread), 0, "id" );
				}
				setMessages(data['results']);

				// set all messages addressed to me as read
				let messages_to_update = data['results'].filter((msg: any) => {
					return (msg.recipient == currentUser.id && msg.read_at == null);
				});
				//console.log("messages_to_update", messages_to_update);

				// iterate over messages and update
				for (const msg of messages_to_update) {
					try{
						const updated_message = await updateMessage(
							parseInt(msg.id),
							{
								"read_at": moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
							}
						);
						console.log("updated_message", updated_message);
					}catch(e){
						console.log(e);
					}
				}

			} catch (e) {
				console.log(e);
			}
			setLoading(false);



		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const writeReply = async (message_text) => {
		console.log("writeReply", message_text);

		// check if the first message is from the current user
		// if so, then the recipient is the other user
		if(messages[0].sender == currentUser.id){
			console.log("recipient", messages[0].recipient);
		}

		// check if the first message has a thread
		// if so, then the thread is the same
		// if not, then the thread is the id of the first message
		if(messages[0].thread == null){
			console.log("thread", messages[0].thread);
			messages[0].thread = messages[0].id;
			// update the first message
			try{
				const updated_message = await updateMessage(
					messages[0].id,
					messages[0]
				);
				console.log("updated_message", updated_message);
			}catch(e){
				console.log(e);
			}
		}




		let new_message_data = 
		{
			//"id": 133,
			"subject": `Re: ${messages[0].subject}`,
			"body": message_text,
			"sender": currentUser.id,
			//"sender_fullname": "Admin Admin",
			"recipient": messages[0].sender == currentUser.id ? messages[0].recipient : messages[0].sender,
			"email": "",
			"parent": null,
			"thread": messages[0].thread,
			"sent_at": moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
			"read_at": null,
			"replied_at": null,
			"sender_archived": false,
			"recipient_archived": false,
			"sender_deleted_at": null,
			"recipient_deleted_at": null,
			"moderation_status": "a",
			"moderation_by": null,
			"moderation_date": moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
			"moderation_reason": ""
		}

		try{
			const new_message = await createMessage(
				new_message_data
			);
			console.log("new_message", new_message);
			console.log("messages", messages);
			
			setMessages([ ...messages, new_message]);
			//setMessage(  t('profile_page.profile_saved') );
		}catch(e){
			console.log(e);
		}

		scrollToBottom();


	}


	if (loading) {
		return <Loading />;
	}


	let messages_in_order = [];	
	messages_in_order = messages.map((msg, idx: number) => {
		const show_msg = `${msg.subject} - ${msg.body}`;
		const time_stamp = moment(msg.sent_at).format('lll');
		if(msg.sender == currentUser.id){
			return(
				<MessageRight
				message={show_msg}
				timestamp={time_stamp}
				photoURL={msg.recipient_mugshot ? msg.recipient_mugshot : "#"} 
				displayName={msg.receiver_fullname}
				avatarDisp={true}
				/>
			)
		}else{
			return(
				<MessageLeft
				message={show_msg}
				timestamp={time_stamp}
				photoURL={msg.sender_mugshot ? msg.sender_mugshot : "#"} 
				displayName={msg.sender_fullname}
				avatarDisp={false}
				/>
			)
		}	
	});
	console.log("messages_in_order", messages);

	const scrollToBottom = () => {
	  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
	}


	return (
		<div className={classes.container}>

 		{messages_in_order.length > 0 && 
				<Paper className={classes.paper}>
					{(messages.length > 0 && messages[0].sender == currentUser.id) && 
					 <Typography variant="h6" component="div" sx={{ m: 1 }}>
						{t('message_write_page.to')}: {messages[0].recipient_fullname}
						<Avatar alt={messages[0].recipient_fullname} src={messages[0].recipient_mugshot} >
						{messages[0].recipient_fullname}
						</Avatar>
					</Typography>
					}
					<Paper id="style-1" className={classes.messagesBody}>
						{messages_in_order}
						<div ref={messagesEndRef} />
					</Paper>
					<TextInput writeReply={writeReply} label={t('message_page.enter_your_message')}/>			
				</Paper>
		}
	  </div>
	);

}
