import in_progress from "../resources/img/in_progress.svg";
import { teal } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
//import HomeIcon from "@mui/icons-material/Home";
//import { blue, green, grey, red } from "@mui/material/colors";

import get from "lodash/get";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { getDashboardData, getLatestTrainingSession } from "../services/api";
import { RootState } from "../reducers";
import HomeWorkouts from "../components/Dashboard/HomeWorkouts";
import GymWorkouts from "../components/Dashboard/GymWorkouts";

import WeeklyGoalInTime from "../components/Dashboard/WeeklyGoalInTime"
import ActivitiesStatus from "../components/Dashboard/ActivitiesStatus"
import GoalWeeklyProgress from "../components/Dashboard/GoalProgress"
import {
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Divider,
	Typography
  } from '@mui/material';
import {SvgIcon} from '@mui/material';
import { useTranslation } from 'react-i18next';

//import { ReactComponent as InProgressIcon } from '../resources/img/in_progress.svg';

const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		padding: theme.spacing(2),
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/
		width: "100%",
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: "#46a9db",
		"&:hover": {
			backgroundColor: teal[600],
		},
	},
	stat_card: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
}));

export function DashboardPage() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [dashboardData, setDashboardData] = React.useState(null);
	const [homeActivitiesStatData, setHomeActivitiesStatdData] = React.useState( {"O":0, "F":0, "U":0,"N":0} );
	const [gymActivitiesStatData, setGymActivitiesStatData] = React.useState( {"O":0, "F":0, "U":0,"N":0} );
	const [visitsWeeklyData, setVisitsWeeklyData] = React.useState( {} );
	const [weekGoalThisWeekData, setWeekGoalThisWeekData] = React.useState( { home: {count:0, percent:0, goal:0}, gym: {count:0, percent:0, goal:0}, all: {count:0, percent:0, goal:0} } );

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [lastVisit, setLastVisit] = React.useState(null);

	const currentUser = useSelector((state: RootState) => state.user); 

	// set language
	moment.locale(i18n.language);


	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const data = await getDashboardData();
				setDashboardData(data.visits);
				setHomeActivitiesStatdData(data.visits.home_visits.activities_status);
				setGymActivitiesStatData(data.visits.gym_visits.activities_status);
				setVisitsWeeklyData(data.visits.visits_weekly);
				// calc the goal for component
				let this_week_key = moment().format('YYYY') + "_" +  moment().format('WW');

				// server side weekly goal
				const weekly_goal_gym = currentUser.goals?.gym_visits_per_week || 0;
				const weekly_goal_home = currentUser.goals?.home_training_sessions_per_week || 0;
				const weekly_goal = (weekly_goal_gym + weekly_goal_home) || 0;
				
				let _weekGoalThisWeekData = { home: {count:0, percent:0, goal:0}, gym: {count:0, percent:0, goal:0}, all: {count:0, percent:0, goal:0} };
				// gym
				if(this_week_key in  data.visits.gym_visits.weekly){
					const this_week = data.visits.gym_visits.weekly[this_week_key]['count'];
					const percent = weekly_goal_gym ? 100*this_week/weekly_goal_gym : 0;
					_weekGoalThisWeekData.gym = {count: this_week, percent: percent, goal: weekly_goal_gym }
				}else{
					// no data
					_weekGoalThisWeekData.gym = {count: 0, percent: 0, goal: 0 }
				}
				//home
				if(this_week_key in  data.visits.home_visits.weekly){
					const this_week = data.visits.home_visits.weekly[this_week_key]['count'];
					const percent = weekly_goal_home ? 100*this_week/weekly_goal_home : 0;
					_weekGoalThisWeekData.home = {count: this_week, percent: percent, goal: weekly_goal_home }
				}else{
					// no data
					_weekGoalThisWeekData.home = {count: 0, percent: 0, goal: 0 }
				}

				// all
				if(weekly_goal){
					_weekGoalThisWeekData.all = {count: _weekGoalThisWeekData.home.count + _weekGoalThisWeekData.gym.count, 
												percent: 100*(_weekGoalThisWeekData.home.count + _weekGoalThisWeekData.gym.count)/ weekly_goal, goal: weekly_goal}
				}else{
					_weekGoalThisWeekData.all = {count: 0, percent: 0, goal: 0 }
				}

				// set data
				setWeekGoalThisWeekData(_weekGoalThisWeekData);

				
				const visitResponse = await getLatestTrainingSession(
					currentUser.id
				);
				const lastVisit = get(visitResponse, "results[0]");
				if (lastVisit) {
					setLastVisit(lastVisit);
				}
			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let firstHomeVisit = null;
	let lastHomeVisit = null;

	let firstGymVisit = null;
	let lastGymVisit = null;

	let gymVisitCountThisMonth;
	let homeVisitCountThisMonth;
	let homeVisitCountLastMonth;
	let gymVisitCountLastMonth;	
	let gymVisitPercentThisToPreviousMonth;
	let homeVisitPercentThisToPreviousMonth;

	if (dashboardData) {
		const homeVisits = dashboardData.home_visits;
		const gymVisits = dashboardData.gym_visits;


		// if(homeVisits.count) homeVisitCountThisMonth = homeVisits.count;
		// if(gymVisits.count) gymVisitCountThisMonth = gymVisits.count;
		
		// this month
		let this_month_key = moment().format('YYYY') + "_" +  moment().format('MM');
		let last_month_key = moment().subtract(1,'months').format('YYYY') + "_" +  moment().subtract(1,'months').format('MM');

		if(this_month_key in homeVisits.monthly){
			homeVisitCountThisMonth = homeVisits.monthly[this_month_key]['count'];
			if(last_month_key in homeVisits.monthly){
				homeVisitCountLastMonth = homeVisits.monthly[last_month_key]['count'];
				if(homeVisitCountThisMonth  != 0 ){
					homeVisitPercentThisToPreviousMonth = ( 100 * (homeVisitCountThisMonth - homeVisitCountLastMonth)/ (homeVisitCountThisMonth) ).toFixed(0);
				}else{
					homeVisitPercentThisToPreviousMonth = 0;	
				}				
			}else{
				homeVisitCountLastMonth = 0;
				homeVisitPercentThisToPreviousMonth = 100;

			}
		}else{
			homeVisitCountThisMonth = 0;
			homeVisitCountLastMonth = 0;
			homeVisitPercentThisToPreviousMonth = 0;
		}

		if(this_month_key in gymVisits.monthly){
			gymVisitCountThisMonth = gymVisits.monthly[this_month_key]['count'];
			if(last_month_key in gymVisits.monthly){
				gymVisitCountLastMonth = gymVisits.monthly[last_month_key]['count'];
				if(gymVisitCountThisMonth != 0 ){
					gymVisitPercentThisToPreviousMonth = ( 100 * (gymVisitCountThisMonth - gymVisitCountLastMonth)/ (gymVisitCountThisMonth) ).toFixed(0);
				}else{
					gymVisitPercentThisToPreviousMonth = 0;	
				}
			}else{
				gymVisitCountLastMonth = 0;
				gymVisitPercentThisToPreviousMonth = 100;

			}
		}else{
			gymVisitCountThisMonth = 0;
			gymVisitCountLastMonth = 0;
			gymVisitPercentThisToPreviousMonth = 0;
		}

		if (homeVisits.first_visit_date) {
			firstHomeVisit = (
				<p>
					<b>First: </b>{" "}
					{moment(homeVisits.first_visit_date).format("LLL")}
				</p>
			);
		}
		if (homeVisits.last_visit_date) {
			lastHomeVisit = (
				<p>
					<b>Last: </b>
					{moment(homeVisits.last_visit_date).format("LLL")}
				</p>
			);
		}



		if (gymVisits.first_visit_date) {
			firstGymVisit = (
				<p>
					<b>First: </b>
					{moment(gymVisits.first_visit_date).format("LLL")}
				</p>
			);
		}
		if (gymVisits.last_visit_date) {
			lastGymVisit = (
				<p>
					<b>Last: </b>
					{moment(gymVisits.last_visit_date).format("LLL")}
				</p>
			);
		}

	}
    //console.log(11111111111111, InProgressIcon);
	if (loading) {
		return <Loading />;
	}
    const current_month = moment().format('MMMM');
	return (
		<div className={classes.root}>
				<Grid container spacing={3}>

				
								
				</Grid>

				<Grid container spacing={3}>
					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<GoalWeeklyProgress weekly_goal_data={weekGoalThisWeekData}/>
					</Grid >					

					<Grid item lg={4} sm={12} xl={4} xs={12}>
						<HomeWorkouts count={homeVisitCountThisMonth} last_month_count={homeVisitCountLastMonth} percent={homeVisitPercentThisToPreviousMonth} month={current_month}/>
					</Grid >
					<Grid item lg={4} sm={12} xl={4} xs={12}>
						<GymWorkouts count={gymVisitCountThisMonth} last_month_count={gymVisitCountLastMonth} percent={gymVisitPercentThisToPreviousMonth} month={current_month}/>
					</Grid >

					<Grid item lg={12} sm={12} xl={12} xs={12}></Grid>						
				</Grid>

			<Fab
				variant="extended"
				color="primary"
				aria-label="add"
				className={classes.fab}
				onClick={startTraining}
			>
				{lastVisit ? (<RefreshIcon />) : (<AddIcon />)}
				{lastVisit ? t('common.continue_training') : t('common.start_training')}
			</Fab>
			{open ? (
				<StartActivityModal open={open} handleClose={handleClose} />
			) : (
				<Fragment />
			)}
		</div>
	);
}
