import {
	Avatar,
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	Fab,
	Typography,
	Grid
} from "@mui/material";
import { green, teal } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import get from "lodash/get";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";

import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import StartActivityModal from "../components/StartActivityModal";
import { RootState } from "../reducers";
import { getLatestTrainingSession, getVisits } from "../services/api";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import "moment/locale/fi"
import "moment/locale/de"
import "moment/locale/fr"
import "moment/locale/zh-cn"
import "moment/locale/zh-tw"


const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 0,
	},
	avatar: {
		backgroundColor: green[500],
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: "#46a9db",
		"&:hover": {
			backgroundColor: teal[600],
		},
	},
}));

export function TrainingSessionsPage() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const perPage = 100;
	const [isNextPageLoading, setNextPageLoading] = React.useState(false);
	const [hasNextPage, setHasNextPage] = React.useState(true);
	const [loadedPage, setLoadedPage] = React.useState(0);
	const [visits, setVisits] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const startTraining = () => {
		setOpen(true);
	};

	const [lastVisit, setLastVisit] = React.useState(null);

	const currentUser = useSelector((state: RootState) => state.user);

	useEffect(() => {
		async function initData() {
			try {
				const visitResponse = await getLatestTrainingSession(
					currentUser.id
				);
				const lastVisit = get(visitResponse, "results[0]");
				if (lastVisit) {
					setLastVisit(lastVisit);
				}
			} catch (e) {}
		}
		initData();
		// eslint-disable-next-line
	}, []);

	async function loadMoreRows({ startIndex, stopIndex }) {
		setNextPageLoading(true);

		const nextPage = loadedPage + 1;
		const nextVisits = await getVisits(currentUser.id, nextPage, perPage);

		if (nextVisits.results.length + visits.length >= nextVisits.count) {
			setHasNextPage(false);
		}
		setNextPageLoading(false);

		setLoadedPage((prev) => {
			return ++prev;
		});

		setVisits((prevVisits) => {
			return [...prevVisits, ...nextVisits.results];
		});

		return;
	}

	return (
		<div style={{ display: "flex", width: "100%" }}>
			<div style={{ flex: "1 1 auto", height: "100vh" }}>
				<ExampleWrapper
					hasNextPage={hasNextPage}
					isNextPageLoading={isNextPageLoading}
					items={visits}
					loadNextPage={loadMoreRows}
				/>
				<Fab
					variant="extended"
					color="primary"
					aria-label="add"
					className={classes.fab}
					onClick={startTraining}
				>
				{lastVisit ? (<RefreshIcon />) : (<AddIcon />)}
				{lastVisit ? t('common.continue_training') : t('common.start_training')}
				</Fab>
				{open ? (
					<StartActivityModal open={open} handleClose={handleClose} />
				) : (
					<Fragment />
				)}
			</div>
		</div>
	);
}

function ExampleWrapper({
	// Are there more items to load?
	// (This information comes from the most recent API request.)
	hasNextPage,

	// Are we currently loading a page of items?
	// (This may be an in-flight flag in your Redux store for example.)
	isNextPageLoading,

	// Array of items loaded so far.
	items,

	// Callback function responsible for loading the next page of items.
	loadNextPage,
}) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	// If there are more items to be loaded then add an extra row to hold a loading indicator.
	const itemCount = hasNextPage ? items.length + 1 : items.length;
	// Only load 1 page of items at a time.
	// Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
	const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

	// Every row is loaded except for our loading indicator row.
	const isItemLoaded = (index) => !hasNextPage || index < items.length;

	// Render an item or a loading indicator.
	const Item = ({ index, style }) => {
		let content;
    
		if (!isItemLoaded(index)) {
			content = (
				<Card variant="outlined" className={classes.root}>
					<CardContent>{t('workouts_page.loading')}</CardContent>
				</Card>
			);
		} else {
			const visit = items[index];
			const visitId = `/app/workouts/${visit.id}`;
			const visitType = visit.visit_type == 4 ? "H" : "G";
			const is_pending = visit.logout === null ? true: false;
			const bk_color = is_pending ? "#46a9db" : visit.visit_type == 4 ? '#49ed89' : '#4969ed';
			
     		const visitStart = moment(visit.login);
			let duration = t('common.pending') + "...";
			if (visit.logout) {
				const visitEnd = moment(visit.logout);
		
				let _duration = moment.duration(visitEnd.diff(visitStart));
				if( _duration.asMilliseconds()  === 0) {
					duration = "0 min";
				}else{

					duration = _duration.humanize()[0].toUpperCase() + _duration.humanize().substr(1);
				}	
			}
			const title = `${visitStart.format("dddd, MMMM Do YYYY")} ${visitStart.format("LT")}`;
			const status = `${duration} | ${visit.training_program_name}`;

			content = (
				<Card variant="outlined" className={classes.root}>
					<CardActionArea component={RouterLink} to={visitId}>
						<CardHeader
							avatar={
								<Avatar
									aria-label="workout"
									className={classes.avatar}
									style={{backgroundColor: bk_color }}
								>
									{visitType}
								</Avatar>
							}
							action={
								<IconButton aria-label="settings" size="large">
									<MoreVertIcon />
								</IconButton>
							}
							title={title}
							subheader={status}
						/>
					</CardActionArea>
					<Box>
			
			</Box>
				</Card>
			);
		}
		style = {
			...style,
		};

		return <div style={style}>{content} </div>;
	};

	return (
		<InfiniteLoader
			isItemLoaded={isItemLoaded}
			itemCount={itemCount}
			loadMoreItems={loadMoreItems}
		>
			{({ onItemsRendered, ref }) => (
				<AutoSizer>
					{({ height, width }) => {
						return (
							<FixedSizeList
								itemSize={70}
								itemCount={itemCount}
								onItemsRendered={onItemsRendered}
								height={height}
								width={width}
								ref={ref}
							>
								{Item}
							</FixedSizeList>
						);
					}}
				</AutoSizer>
			)}
		
		</InfiniteLoader>

	);
}
