// prettier-ignore
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Axios from "axios";
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Router, Routes, useLocation, BrowserRouter } from "react-router-dom";
import { history } from "./configureStore";
import {
	DashboardPage,
	StatisticsPage,
	HomePage,
	LoginPage,
	LogoutPage,
	TrainingSessionsPage,
	TrainingSessionDetailsPage,
	SettingsPage,
	ProfilePage
} from "./pages";
import { Wrapper } from "./pages/Wrapper";
import { updateToken } from "./services/api";
import authenticator from "./services/authenticator";
import { withRoot } from "./withRoot";
import { ActivityPage } from "./pages/ActivityPage";
import { MessagesPage } from "./pages/MessagesPage";
import { MessagePage } from "./pages/MessageDetailsPage";
import { MessageWritePage } from "./pages/MessageWritePage";


let isRefreshing = false;
const refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
	refreshSubscribers.map((cb) => cb(token));
};

Axios.interceptors.request.use(
	(config) => {
		if (config.url.includes("/login")) {
			return config;
		}
		const accessToken = localStorage.getItem("access_token");
		const serverUrl = localStorage.getItem("server_url");
		if (!config.url.includes(serverUrl)) {
			return config;
		}
		config.headers["Authorization"] = `Bearer ${accessToken}`;
		return config;
	},
	(error) => {
		console.error(error);
		return Promise.reject(error);
	}
);

Axios.interceptors.response.use(null, (error) => {
	const {
		config,
		response: { status },
	} = error;
	const originalRequest = config;

	if (status === 401) {
		if (!isRefreshing) {
			isRefreshing = true;
			updateToken()
				.then((data) => {
					localStorage.setItem("secretKey", "allowmein");
					localStorage.setItem("access_token", data.access_token);
					localStorage.setItem("refresh_token", data.refresh_token);

					isRefreshing = false;
					onRefreshed(data.access_token);
				})
				.catch((error2) => {
					localStorage.removeItem("access_token");
					localStorage.removeItem("refresh_token");
					localStorage.removeItem("secretKey");
					refreshSubscribers.length = 0;
					console.error(error2);
					window.location.href = "/login";
				});
		}

		const retryOrigReq = new Promise((resolve) => {
			subscribeTokenRefresh((token) => {
				// replace the expired token and retry
				originalRequest.headers.Authorization = `Bearer ${token}`;
				resolve(Axios(originalRequest));
			});
		});
		return retryOrigReq;
	}

	return Promise.reject(error);
});

// const PrivateRoute = ({ component: Component, ...rest }) => (
// 	<Route
// 		{...rest}
// 		children={(props) =>
// 			authenticator.isLoggedIn ? (
// 				<Component {...props} />
// 			) : (
// 				<Navigate
// 					to={{
// 						pathname: "/login",
// 						state: { from: props.location },
// 					}}
// 				/>
// 			)
// 		}
// 	/>
// );

const NoMatch = () => {
	let location = useLocation();

	return (
	<div>
		<h3>
			No match for <code>{location.pathname}</code>
		</h3>
	</div>
	);
};

function AllRoutes() {
	const classes = useStyles();

	return (
		<div className={classes.content}>
						
			<Routes>
				<Route path="/" element={<HomePage />} ></Route>
				<Route path="/login"  element={<LoginPage />} />

				<Route path="/app">

						<Route path="/app/logout" element={<Wrapper><LogoutPage /></Wrapper>} />

						
						<Route path="/app/dashboard" element={<Wrapper><DashboardPage /></Wrapper>} />

						<Route path="/app/workouts" element={<Wrapper><TrainingSessionsPage /></Wrapper>} />
						<Route path="/app/statistics" element={<Wrapper><StatisticsPage /></Wrapper>} />

						<Route path="settings" element={<Wrapper><SettingsPage /></Wrapper>} />
						<Route path="/app/workouts/:id" element={<Wrapper><TrainingSessionDetailsPage /></Wrapper>} />
						<Route path="/app/workouts/:id/activity/:activityId" element={<Wrapper><ActivityPage /></Wrapper>} />
						<Route path="/app/messages" element={<Wrapper><MessagesPage /></Wrapper>} />
						<Route path="/app/message/:id" element={<Wrapper><MessagePage /></Wrapper>} />
						<Route path="/app/message/write" element={<Wrapper><MessageWritePage /></Wrapper>} />
						<Route path="/app/profile" element={<Wrapper><ProfilePage /></Wrapper>} />
				</Route>
				<Route path="*" element={<NoMatch />} />
			</Routes>
			
		</div>
	);
}

function App() {
	const classes = useStyles();

	return (
		<BrowserRouter>
			<div className={classes.root}>
				<div className={classes.appFrame}>
					<AllRoutes />
				</div>
			</div>
		</BrowserRouter>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "absolute",
		backgroundColor: "#0063be"
	},
	navIconHide: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	drawerHeader: { ...theme.mixins.toolbar },
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "100%"

	},
}));

export default withRoot(App);
