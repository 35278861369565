import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material';

import { RootState } from "../../reducers";
import { toInteger } from 'lodash';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));


const LANGUAGES = {
  'en': 'English',
  'cs': 'Czech',
  'da': 'Danish',
  'nl': 'Dutch',
  'fi': 'Finnish',
  'fr': 'French',
  'de': 'German',
  'hu': 'Hungarian',
  'it': 'Italian',
  'ja': 'Japanese',
  'ko': 'Korean',
  'nb': 'Norwegian Bokmal',
  'pl': 'Polish',
  'pt': 'Portuguese',
  'ru': 'Russian',
  'zh-cn': 'Simplified Chinese',
  'sk': 'Slovak',
  'es': 'Spanish',
  'sv': 'Swedish',
  'th': 'Thai',
  'zh-tw': 'Traditional Chinese',
  'tr': 'Turkish'
}


const UserProfile = props => {
  const { className, ...rest } = props;

  const [values, setValues] = useState({
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    gender: props.user.profile.gender,
    language: props.user.profile.language,
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader={props.t('profile_page.sub_title')}
          title={props.t('profile_page.title')}
        />
        <Divider />
        <CardContent>
          <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText={props.t('profile_page.enter_first_name')}
                label={props.t('common.first_name')}
                margin="dense"
                name="first_name"
                onChange={handleChange}
                required
                value={values.first_name}
                variant="outlined"
              />


              <TextField
                fullWidth
                helperText={props.t('profile_page.enter_last_name')}
                label={props.t('common.last_name')}
                margin="dense"
                name="last_name"
                onChange={handleChange}
                required
                value={values.last_name}
                variant="outlined"
              />
              <br/><br/>
              <FormControl sx={{ m: 0, minWidth: 120 }} required >
                <InputLabel id="gender-select-label">{props.t('common.gender')}</InputLabel> 
                <Select
                  fullWidth

                  labelId='gender-select-label'
                  label={props.t('common.gender')}
                  id='gender-select'
                  value={values.gender}
                  name="gender"
                  variant="outlined"
                  margin="dense"          
                  onChange={handleChange}

                >
                  <MenuItem value={'M'}>{props.t('common.male')}</MenuItem>
                  <MenuItem value={'F'}>{props.t('common.female')}</MenuItem>
                </Select>
                <FormHelperText>{props.t('profile_page.select_gender')}</FormHelperText>
              </FormControl>
              <br/><br/>

              <FormControl sx={{ m: 0, minWidth: 220 }} required fullWidth>
                <InputLabel id="language-select-label">{props.t('profile_page.language')}</InputLabel> 
                <Select
                  fullWidth
                  labelId='language-select-label'
                  label={props.t('profile_page.language')}
                  id='language-select'
                  value={values.language}
                  name="language"
                  margin="dense"          
                  onChange={handleChange}

                >
                  {Object.keys(LANGUAGES).map((key, index) => (
                    <MenuItem key={index} value={key}>{LANGUAGES[key]}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{props.t('profile_page.select_language')}</FormHelperText>
              </FormControl>

            </Grid>          
        </CardContent>
        <Divider />

        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {props.save_user(values);}}
          >
            {props.t('common.save')}
          </Button>
        </CardActions>
      </form>
      <br/><br/>
    </Card>
  );
};

UserProfile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  save_user: PropTypes.func
};

export default withTranslation()(UserProfile);
