import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeIcon from '@mui/icons-material/Home';
//import { blue, green, grey, red } from "@mui/material/colors";
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: "#49ed89 !important",
    backgroundImage: "linear-gradient(-180deg,#80ef9c,#69ed89)",
    height: "56px !important",
    width: "56px !important",
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceDownIcon: {
    color: theme.palette.error.dark
  },
  differenceDownValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  differenceEqualIcon: {
    color: theme.palette.success.dark
  },
  differenceEqualValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  differenceUpIcon: {
    color: theme.palette.success.dark
  },
  differenceUpValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const HomeWorkouts = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {props.t('dashboard_page.home_workouts_with_month', {month: props.month})}
            </Typography>
            <Typography variant="h3">{props.count}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <HomeIcon className={classes.icon}/>
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {props.percent > 0 && <ArrowUpwardIcon className={classes.differenceUpIcon} />}
          {props.percent < 0 && <ArrowDownwardIcon className={classes.differenceDownIcon} />}
          {props.percent == 0 && <ArrowForwardIcon className={classes.differenceEqualIcon} />}
          {props.percent > 0 && <Typography className={classes.differenceUpValue} variant="body2">{props.percent}%</Typography>}
          {props.percent < 0 && <Typography className={classes.differenceDownValue} variant="body2">{props.percent}%</Typography>}
          {props.percent == 0 && <Typography className={classes.differenceEqualValue} variant="body2">{props.percent}%</Typography>}  
          <Typography
            className={classes.caption}
            variant="caption"
          >

            {props.t('dashboard_page.since_last_month')} ({props.last_month_count})
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

HomeWorkouts.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  month: PropTypes.string,
  percent: PropTypes.string,
  last_month_count: PropTypes.number
};

export default withTranslation()(HomeWorkouts);
