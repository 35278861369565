import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	IconButton,
	Typography,
	Grid,
	Button,
	Chip
} from "@mui/material";
import { blue, green, grey, red, yellow } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import CreateIcon from "@mui/icons-material/Create";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import moment, { Moment } from "moment";
import React, { useEffect, useRef } from "react";
import ReactPlayer from 'react-player'
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import { Loading } from "../components";
import { RootState } from "../reducers";
import { getVisitById } from "../services/api";
import { updateVisit } from "../services/api";

import { getVisitActivityById, updateVisitActivity } from "../services/api";
import clsx from 'clsx';
import { ContactsOutlined } from "@mui/icons-material";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';


import "moment/locale/fi"
import "moment/locale/de"
import "moment/locale/fr"
import "moment/locale/zh-cn"
import "moment/locale/zh-tw"





const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 275,
		// marginBottom: "1rem",
		// marginTop: "1rem",

		background: 'white',
		position: "sticky",
        top: 0,
        bottom: 20, 


	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	backButton: {
		margin: "1rem",
	},
	media: {
		// height: 240,
		backgroundSize: "contain",
	},
	pos: {
		marginBottom: 12,
	},
	statusOver: {
		backgroundColor: blue[200],
	},
	statusNot: {
		backgroundColor: "white",
	},
	statusUnder: {
		backgroundColor: red[200],
	},
	statusFully: {
		backgroundColor: green[200],
	},
	statusStarted: {
		color: "black",
		backgroundColor: yellow[200],
	},
	titleContainer: {
		display: "flex",
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		alignContent: "center",
		height: "54px",
		backgroundColor: "#8fd400",
		justifyContent: "center",
		marginTop: "0px",

	},
	title: {
		fontSize: 32,
		fontWeight: "normal",
		color: "white",
		marginBottom: "0px"
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
		  duration: theme.transitions.duration.shortest,
		}),
		padding: "6px 0px",
		minWidth: 0,
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	instruction: {
		color: "darkgreen",
		marginBottom: 0
	},
	description: {
	},
	fullHeightCard: {

	},
	endSessionButton: {
		color: theme.palette.common.white,
		backgroundColor: "#46a9db",
		"&:hover": {
			backgroundColor: "#4619db",
		},
	}
	
	}));
const StyledPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body1,
	padding: theme.spacing(2),

	color: theme.palette.text.primary,
	}));
// duration for timer
function timeSinceStart(login : Moment, logout: Moment){
	let _duration = "AAAA";

	console.log(_duration);
    return true;

};
let interval;
export function TrainingSessionDetailsPage(props) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();	
	const location = useLocation();

	const availableActivities = useSelector((state: RootState) => {
		return state.activities;
	});
	let { id } = useParams<{id:string}>();
	const [visit, setVisit] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [updatingActivity, setUpdatingActivity] = React.useState(false);
	const [updatingActivityId, setUpdatingActivityId] = React.useState(null);
	const [expanded, setExpanded] = React.useState([]);
	const [message, setMessage] = React.useState(null); // for Snapbar

	// set language
	moment.locale(i18n.language);

	const handleExpandClick = (idx: number) => {
		let _expanded = [...expanded]
		
		_expanded[idx] = !_expanded[idx]
		setExpanded(_expanded);
	};
	const handleCloseMessage = () => {
		setMessage(null);
	};

	const [timeElapsed, setTimeElapsed] = React.useState("");
	
	

	const  endSession = async () => {

		try {
			// remove activities from payload
			const {activities, ...uploadVisitData} = visit;
			// fill in logout
			uploadVisitData.logout = moment().toISOString();
			const updatedVisit = await updateVisit(
				parseInt(visit.id, 10),
				uploadVisitData
			);
			setVisit(updatedVisit);
			setMessage("Session ended.");
			setTimeElapsed("");
			clearInterval(interval);			
		} catch (e) {
			console.log(e);
		}
		

	}

	useEffect(() => {
		console.log("useEffect");
		async function initData() {
			setLoading(true);
			try {
				const visitResponse = await getVisitById(parseInt(id, 10));
				// filter visit activities by created id
				if(visitResponse.activities){
					visitResponse.activities.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
				}
				setVisit(visitResponse);
				
				if(!visitResponse.logout){
					// inital
					setTimeElapsed( te => {
						const dur = moment.duration( moment().diff(moment(visitResponse.login)) );
						return ("0"+dur.hours()).substr(-2) + ":" + ("0"+dur.minutes()).substr(-2) + ":" + ("0"+dur.seconds()).substr(-2);
					});
                    // timer
					interval = setInterval(() => {
						setTimeElapsed( te => {
							const dur = moment.duration( moment().diff(moment(visitResponse.login)) );
							return ("0"+dur.hours()).substr(-2) + ":" + ("0"+dur.minutes()).substr(-2) + ":" + ("0"+dur.seconds()).substr(-2);
						});
					}, 1000);
				};
				// expand activities in view - state
				let _expand_false_all = visitResponse.activities.map(() => { return false;});
				
				setExpanded(_expand_false_all);

			} catch (e) {
				console.log(e);
			}
			setLoading(false);

		}
		initData();

		return () => {
			console.log("clearinterval");
			clearInterval(interval);
		};
	}, [id]);



		const onStatusUpdate = async (activity_id: string, update: any) => {
			setUpdatingActivity(true);
			setUpdatingActivityId( parseInt(activity_id, 10) );
			let activity:object = {};

			try {
				const updatedActivity = await updateVisitActivity(
					parseInt(activity_id, 10),
					Object.assign({}, activity, update)
				);
				//setActivity(updatedActivity);
				//setMessage("Your activity has been updated successfully");
			} catch (e) {
				console.log(e);
			}
			setUpdatingActivity(false);
			setUpdatingActivityId(null);
		};

		const saveActivityAsFullyPerformed = (activity_id: string) => {
			// alert(activity_id);
			// console.log("ssss");
			let _activity = null;
			for (const activity of visit.activities) {
				if(activity.id === activity_id){
					_activity = activity;
				}
			}
			// modify _activity
			const activityDetails = availableActivities.find(
				(availableActivity) => {
					return availableActivity.id === _activity.activity;
				}
			);
			const activity_type = activityDetails.all_activity.activity_type;

			_activity["status"] = "F";
			_activity["results"] = null;

			_activity["start_time"] = moment()
			_activity["end_time"] = moment()

			if([1, 3, 7, 8, 9, 56].includes(activity_type) ){
				_activity["results"] = _activity['settings'];
			}


			// TODO add results relating to different activities' types

			onStatusUpdate(activity_id, _activity);			
		};
	
	const getStatusClass = (status: string) => {
		switch (status) {
			case "O":
				return classes.statusOver;
			case "N":
				return classes.statusNot;
			case "U":
				return classes.statusUnder;
			case "F":
				return classes.statusFully;
			case "S":
				return classes.statusStarted;
		}
		return "";
	};
	const getStatusName = (status: string) => {
		switch (status) {
			case "O":
				return "Over Performed";
			case "N":
				return "Not Performed";
			case "U":
				return "Under Performed";
			case "F":
				return "Fully Performed";
			case "S":
				return "Started";
		}
		return "Unknown";
	};		
	const getStatusColor = (status: string) => {
		switch (status) {
			case "O":
				return blue[500];
			case "N":
				return grey[500];
			case "U":
				return red[500];
			case "F":
				return green[500];
			case "S":
				return yellow[500];
		}
		return grey[100];
	};


	if (loading || !visit) {
		console.log("Loading");
		return <Loading />;
	}

	// interval = setInterval(() => {
	// 	if(visit){
	// 		timeSinceStart(moment(visit.login), moment(visit.logout));
	// 	}
	// 	console.log("Here.");
	// }, 1000);

	const visitStart = moment(visit.login);
	let duration = t('common.pending');

	if (visit.logout) {
		const visitEnd = moment(visit.logout);
		duration = moment.duration(visitEnd.diff(visitStart)).humanize();
	}



	const title = `${visitStart.format("dddd, MMMM Do YYYY")} ${t('training_session_page.duration', {duration: duration})}`;
	const serverUrl = localStorage.getItem("server_url");
	const activities = [];
	let numberOfPerformedActivities=0, numberOfActivities=visit.activities.length; 
	for (const [act_index, activity] of visit.activities.entries() ) {
		const activityDetails = availableActivities.find(
			(availableActivity) => {
				return availableActivity.id === activity.activity;
			}
		);
		const image =
			activityDetails.picture && activityDetails.picture.length > 1
				? activityDetails.picture
				: activityDetails.all_activity.picture;
		const imageFallback = "activity_images/freetrainer_HPEmzfT.png"; // fallback to freetrainer image
		// use cdn
		const cdnAddress = "https://cdn01.hursmarttouch.com"; //"https://d3ldm7xf6jmsyw.cloudfront.net";
		const imageUrl = `${image}`.startsWith('activity_images') ? `${cdnAddress}/media/${image}` : `${serverUrl}media/${image}`;
		
		const imageFallbackUrl = `${serverUrl}media/${imageFallback}`;
		let animation =
			activityDetails.animation && activityDetails.animation.length > 1
				? activityDetails.animation
				: (activityDetails.all_activity.animation && activityDetails.all_activity.animation.length > 1 ? activityDetails.all_activity.animation: null);
		animation = animation ? `${serverUrl}media/${animation}` : animation;
		
		let video =
			activityDetails.video && activityDetails.video.length > 1
				? activityDetails.video
				: (activityDetails.all_activity.video && activityDetails.all_activity.video.length > 1 ? activityDetails.all_activity.video: null);

		const onMediaFallback = (event) => {
			event.target.src = imageFallbackUrl;
			//console.log("onMediaFallback-imageFallbackUrl", imageFallbackUrl);
		};
		const activityId = `/app/workouts/${visit.id}/activity/${activity.id}`;
		// is performed
		if(activity.status != 'N') numberOfPerformedActivities++;

		// description and comments		
		let description = null;
		let comments = null;
		if(activityDetails?.description){
			description = activityDetails.description;
		}else{
			if( !([56].includes(activityDetails.all_activity.activity_type)) && activityDetails.all_activity?.description ){
				description = activityDetails.all_activity.description;
			}

		}
		if(activityDetails?.comments){
			comments = activityDetails.comments;
		}
		// instruction		
		let instruction = null;
		if(activity?.instruction){
			instruction = activity.instruction;
		}
		// hur activity
		if( activityDetails.all_activity.activity_type === 1 ){
			comments = "This exercise should be performed on HUR machine." 
		}

		let results = null;
		let settings = activity.settings;
		if (activity?.settings?.parameters) {
			if (activity.results && typeof activity.results === "object") {
				results = activity.results;
			} else {
				results = activity.settings.parameters;
			}
		} else {
			if (activity.results && typeof activity.results === "object") {
				results = activity.results;
			} 
		}

		const activityType = activityDetails.all_activity.activity_type;
		let parameters = [];

		if (settings && activityType === 7) {
			parameters = [
				<div key="time">
					<b>Time (min): </b>
					{settings.time}
				</div>,
			];
		}
		if (settings && activityType === 8) {
			parameters = [
				<div key="performances-8">
					<b>Performances: </b>
					{settings.performances}
				</div>,
			];
		}
		if (settings && activityType === 9) {
			const load = (activity.settings.init_load / 1000).toFixed(0);
			parameters = [
				<div key="performances-9">
					 {load} kg x {settings.performances}
				</div>,
			];
		}
		if (activityType === 56 && Array.isArray(settings.parameters)) {
			parameters = settings.parameters.map((result, idx: number) => {
				if (result.type === "integer") {
					return (
						<div key={idx}>
							<b>{result.name}: </b>
							{result.value}
						</div>
					);
				}
				if (result.type === "float") {
					return (
						<div key={idx}>
							<b>{result.name}: </b>
							{result.value}
						</div>
					);
				}
				if (result.type === "time") {

					let _min = "", _sec = "";
					let result_string;
					if (result.unit === "min") {
						const m = Math.floor(result.value);
						_min = m.toString();
						result_string = `${_min} ` + "min";
					}
					if (result.unit === "sec") {
						const s = result.value
						_sec = s.toString();;
						result_string = `${_sec} ` + "sec";
					}
					if (result.unit === "min_sec") { 
						const m = Math.floor(result.value / 60);
						const s = result.value % 60;
						_min = m < 10 ? `0${m}`: `${m}`;
						_sec = s < 10 ? `0${s}`: `${s}`;
						result_string = `${_min + ":" + _sec} ` + "(mm:ss)";
					}


					return (
						<div key={idx}>
							<b>{result.name}: </b>
							{result_string}
						</div>
					);
				}
				return (
					<div key={idx}>
						<b>{result.name}: </b>
						{result.value}
					</div>
				);
			});
		}
		// hur activity
		if (activityType === 1 && "sets" in settings  && Array.isArray(settings.sets)) {
			parameters = settings.sets.map((set, idx: number) => {
				//const unit = "units" in results && results.unit = "lbs" - TODO
				//const load = results
				// check if there are results
				if (results && "sets" in results  && Array.isArray(results.sets) && results.sets.length > idx ){
					return (
						<Typography variant="body2" key={idx}>
								{idx+1}. {results.sets[idx].load/1000.} <sup>(/{set.load/1000.})</sup> kg x {results.sets[idx].reps} <sup>(/{set.reps})</sup> reps
						</Typography>
					)
				} else{
					return (
						<Typography variant="body2" key={idx}>
								{idx+1}. {set.load/1000.} kg x {set.reps} reps
						</Typography>
					)
				}
			});
			//console.log("Hur Activity results", results);
			parameters = [ <div><b>Sets</b></div>, ...parameters];
		}		
		let activityAction = null;
		if (!visit.logout) {
			activityAction = (
				<div >
					{updatingActivity && updatingActivityId === activity.id && (<CircularProgress color="primary"/>)}
					
					{activity.status ==='N' && ([3, 7, 8, 9, 56].includes(activityType)) && updatingActivityId != activity.id &&
					
						<IconButton
                            aria-label="perform"
                            onClick={() => { saveActivityAsFullyPerformed( activity.id ) }}
                            size="large">
							<CheckCircleIcon style={{ fontSize: 38}} />
						</IconButton>
					}
					{activity.status !=='N' && ([3, 7, 8, 9, 56].includes(activityType)) && updatingActivityId != activity.id &&
					<IconButton aria-label="update" component={RouterLink} to={activityId} size="large">
						<CreateIcon style={{ fontSize: 38}} />
					</IconButton>
					}
				</div>
			);


		}
		activities.push(
			
			<StyledPaper key={activity.id} elevation={4} className={getStatusClass(activity.status) }
				sx={{
				my: 0,
				mx: 'auto',
				p: 1,
				maxWidth: '100%',
				minWidth: '100%'
				}}
			>
				{/* <CardHeader
					action={activityAction}
					title={activity.activity_name}
				/> */}

					<Grid container spacing={3}  alignItems="center" justifyContent="center" 
						component={RouterLink} to={ !visit.logout ? `${activityId}?e=1` : activityId} style={{ textDecoration: 'none', color: "black" }}>
						
						<Grid item xs={6}>
						<Typography variant="h5">{activity.activity_name}</Typography>
							
						</Grid>							
						<Grid item xs={4}>
							<CardMedia
								className={classes.media}
								image={imageUrl}
								component="img"
								onError={onMediaFallback}
								title={activity.activity_name}
							/>
						</Grid>
						<Grid item xs={2}>


							<IconButton component={RouterLink} to={ !visit.logout ? `${activityId}?e=1` : activityId} size="large">
								<ArrowForwardIosIcon style={{ fontSize: 18}} />
							</IconButton>


						</Grid>
						{/* {instruction && (<Grid item xs={12}>
							<Typography className={classes.instruction} paragraph>{instruction}</Typography>
							</Grid>)
						} */}
					
					</Grid>


				<Collapse in={expanded[act_index]} timeout="auto" unmountOnExit>
					<div>
					<Typography variant="h6">Description</Typography>
					<Typography paragraph>
						{description}
					</Typography>
					{comments && 
						(<div><Typography variant="h6">Comments</Typography>
						<Typography paragraph>
							{comments}
						</Typography></div>)
					}				
					{/* for videos */}
					{video &&
						(<div><Typography variant="h6">Video</Typography>
							<div className="player-wrapper" style={{ position: "relative", paddingTop: "56.25%" }}>
								<ReactPlayer id="video-player" ref={ref} url={video} controls={true}
									width="100%" height="100%" muted={false} style={{ position: "absolute", top: 0, left: 0 }} onEnded={loop_video} onProgress={handleProgress}/>
							</div>
						 </div>)
					}
					{/* for pictures like */}
					{animation  && ["jpg", "jpeg", "gif", "png"].includes( animation.split('.').pop()) &&
						(<div><Typography variant="h6">Animation</Typography>
							<div className="player-wrapper" style={{ textAlign: "center" }}>
								<img src={animation} 
									style={{  }} />
							</div>
						 </div>)
					}
					</div>
				</Collapse>



			</StyledPaper>
		);


	}
	var this_player:any;

	function loop_video(){
		//let this_player = document.getElementById("video-player");
		this_player.playing = true;
		this_player.seekTo(66, 'seconds');
		
		console.log("loop");

	}

	function handleProgress(state){
		//console.log('onProgress', state);
		//console.log('this_player', this_player);
		// if(state['playedSeconds']>72){
			
		// 	this_player.seekTo(67, 'seconds');
		// 	this_player.playing = true;

		// }


	  }

	function ref(player){
		this_player = player;
	}
	function SlideTransition(props) {
		return <Slide {...props} direction="up" />;
	}




	return (
		<div style={{ minHeight: "1000px", overflowX: "initial", marginBottom: "4px"}}>




			<Card  variant="outlined" style={{ overflowX: "initial", marginBottom: "4px"}}>

				<CardContent>
					<Typography variant="h6" component="h2">
						{visit?.training_program_name ?? "-"}
					</Typography>
					<Typography className={classes.pos} color="textSecondary">
						{title} {timeElapsed}
					</Typography>
					
					<Typography>
						{ t('training_session_page.activities_performed', { count: numberOfPerformedActivities, numberOfActivities: numberOfActivities }) } &nbsp;
						{!visit.logout && 
							(<Button className={classes.endSessionButton}
								onClick={() => { endSession(); }}
							 >
							 {t('training_session_page.end_session')}
							 </Button>	
							)
						}						
					</Typography>
					


				</CardContent>
			</Card>
			
			<Grid container spacing={1} style={{ padding:16 }}>
				{activities.map((activity, index) => (
					<Grid item key={index} xs={12} sm={12} md={4} lg={4}>{activity}</Grid>
				))}			

			{message && <Snackbar
				open={!!message}
				autoHideDuration={3000}
				onClose={handleCloseMessage}
				TransitionComponent={SlideTransition}
				sx={{ bottom: { xs: 60, sm: 0 } }}
			>
				<Alert severity="success">
					{message}
				</Alert>
			</Snackbar>
			}
			
			</Grid>
			<Grid container spacing={1} style={{ padding:16, margin:30 }}></Grid>
		</div>
	);
}
