import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import AddIcon from "@mui/icons-material/Add";
import Alert from '@mui/material/Alert';
import get from "lodash/get";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../reducers";
import {
	createVisit,
	getLatestTrainingSession,
	getTrainings,
} from "../services/api";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import { Loading } from "./Loading";
import { NoTrainingsDialog } from "./NoTrainingsDialog";
import omit from "lodash/omit";
import { useTranslation } from 'react-i18next';

function getModalStyle() {
	return {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: 400,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		formControl: {
			margin: theme.spacing(1),
			width: "100%",
		},
		confirmButton: {
			marginTop: 40,
		},
		error: {
			marginTop: 40,
		},
	})
);

const HomeRadio = withStyles({
	root: {
	  color: "#49ed89",
	  '&$checked': {
		color: "#49ed89",
	  },
	},
	checked: {},
  })((props) => <Radio color="default" {...props} />);

const GymRadio = withStyles({
	root: {
	  color: "#4969ed",
	  '&$checked': {
		color: "#4969ed",
	  },
	},
	checked: {},
  })((props) => <Radio color="default" {...props} />);

export default function StartActivityModal(props) {
	const navigate = useNavigate();
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(null);
	const [selectedTrainingId, setSelectedTrainingId] = React.useState(0);
	const [trainings, setTrainings] = React.useState([]);
	const [modalStyle] = React.useState(getModalStyle);
	const [selectedVisitType, setSelectedVisitType] = React.useState(4); // default it is a home visit type 4
	let modalBody;
	const { open, handleClose } = props;

	const currentUser = useSelector((state: RootState) => state.user);

	const setTraining = (event) => {
		setSelectedTrainingId(event.target.value);
	};

	const setVisitType = (event) => {
		setSelectedVisitType( parseInt(event.target.value, 10) );
	};

	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const visitResponse = await getLatestTrainingSession(
					currentUser.id
				);
				let lastVisit = null;
				if(visitResponse?.results?.length > 0){
					lastVisit = get(visitResponse, "results[0]");
				}else{
					lastVisit = null;
				}
				if (lastVisit) {
					navigate(`/app/workouts/${lastVisit.id}`);
				} else {
					const _trainings = await getTrainings(currentUser.id);
					if(_trainings?.count > 0){
						setTrainings(_trainings.results);
					}else{
						setTrainings([]);
					}
				}
			} catch (e) {
				console.log(e);

			}
			setLoading(false);
		}
        initData();
        // eslint-disable-next-line
	}, []);

	const onCreateSession = async () => {
		if (selectedTrainingId === 0) {
			setError("Please select a training first!");
			return;
		}
		const selectedTraining = trainings.find(
			(training) => training.id === selectedTrainingId
		);
		if (!selectedTrainingId) {
			setError("Unable to find training");
			return;
		}
		setError(null);
		setLoading(true);
		try {
			const newVisit = await createVisit({
				login: new Date().toISOString(),
				logout: null,
				user: currentUser.id,
				visit_type: selectedVisitType,
				training_program_name: selectedTraining.name,
				training_program: selectedTraining.id,
				facility: currentUser.membership.facility,
				comments: 'Created through the app',
			});
			navigate(`/app/workouts/${newVisit.id}`);
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	};

	if (trainings.length > 0) {
		const options = [
			<MenuItem value={0} key={0}>
				{t('start_training_modal.choose_your_training')}
			</MenuItem>,
		];
		for (let training of trainings) {
			options.push(
				<MenuItem value={training.id} key={training.id}>
					{training.name}
				</MenuItem>
			);
		}
		modalBody = (
			<div>
				<FormControl className={classes.formControl}>
					<FormLabel component="legend">{t('common.location')}</FormLabel>
					<RadioGroup row aria-label="position" name="position" defaultValue="4" onChange={setVisitType}>
						<FormControlLabel
							value="4"
							control={<HomeRadio/>}
							label={t('common.home')}
							labelPlacement="start"
						/>
						<FormControlLabel
							value="1"
							control={<GymRadio/>}
							label={t('common.gym')}
							labelPlacement="start"
						/>
					</RadioGroup>
					<br/>
					<FormLabel component="legend">{t('common.training')}</FormLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={selectedTrainingId}
						onChange={setTraining}
					>
						{options}
					</Select>
				</FormControl>
				<Button
					variant="contained"
					color="primary"
					className={classes.confirmButton}
					startIcon={<AddIcon />}
					onClick={onCreateSession}
				>
					{t('start_training_modal.create_session')}
				</Button>
			</div>
		);
	}else{
		if(!loading){
			console.log('No trainings found');
			modalBody = (
			<div>
				<Typography variant="h6" gutterBottom>
					{t('start_training_modal.no_trainings_title')}
				</Typography>
				<Typography variant="body1" gutterBottom>
					{t('start_training_modal.no_trainings_message')}
				</Typography>
				<Button
					variant="contained"
					color="warning"
					className={classes.confirmButton}
					startIcon={<HighlightOffIcon />}
					onClick={handleClose}
				>
					{t('common.close')}
				</Button>
			</div>
		);	
	}

	}
	if (loading) {
		modalBody = <Loading />;
	}

	const bodyWrapper = (
		<div style={modalStyle} className={classes.paper}>
			{trainings.length != 0 && <h2 id="simple-modal-title">{t('start_training_modal.start_workout_title')}</h2>}
			{modalBody}
			{error ? (
				<Alert severity="error" className={classes.error}>
					{error}
				</Alert>
			) : (
				""
			)}
		</div>
	);	

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<div>{bodyWrapper}</div>
		</Modal>
	);
}
