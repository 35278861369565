import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress
} from '@mui/material';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { blue, green, grey, red } from "@mui/material/colors";
import HomeIcon from '@mui/icons-material/Home';
import moment from 'moment';
import { withTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: "#ed4949 !important",
    backgroundImage: "background-image: linear-gradient(-180deg,#ef5c60,#ed4949);",
    height: "32px !important",
    width: "32px !important",
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const GoalProgress = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              WEEKLY GOAL (this week)
            </Typography>
            <Typography variant="h3">{props.weekly_goal_data.percent}%</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={Number(props.weekly_goal_data.percent)}
          variant="determinate"
        />
      </CardContent>
    </Card>
  );
};

const GoalWeeklyProgress = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const daysUntilWeekend = 6 - moment().day()

  const weekly_visits_combined_data = {
    datasets: [
      {
        data: [ props.weekly_goal_data.all.percent, props.weekly_goal_data.all.percent < 100 ? 100 - props.weekly_goal_data.all.percent: 0 ], 
        backgroundColor: [
          blue[500],
          grey[100],
        ],
        borderWidth: 2,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ]

  };

  const weekly_home_visits_data = {
    datasets: [
      {
        data: [ props.weekly_goal_data.home.percent, props.weekly_goal_data.home.percent < 100 ? 100 - props.weekly_goal_data.home.percent : 0],
        backgroundColor: [
          "#49ed89",
          grey[100],
        ],
        borderWidth: 2,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ]

  };

  const weekly_gym_visit_data = {
    datasets: [
      {
        data: [ props.weekly_goal_data.gym.percent, props.weekly_goal_data.gym.percent < 100 ? 100 - props.weekly_goal_data.gym.percent: 0],
        backgroundColor: [
          "#4969ed",
          grey[100],
        ],
        borderWidth: 2,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ]

  };
  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    layout: { padding: 0 },
    animationEasing : "easeOutQuart",
    cutout: '70%',
    segmentShowStroke : false

  };
  const textCenter = {
    id: 'text-center',
    beforeDatasetsDraw: (chart) => {
      const { width, height } = chart;
      const { ctx } = chart;

      //console.log(width, height);

      ctx.restore();
      const fontSize = (height / 76).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';

      const value = chart.config.data.datasets[0].data[0];
      
      const text = `${ value.toFixed(0) }%`;

      ctx.fillText(text, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);

      // ctx.fillText("OAS", chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
      // ctx.font = '14px Material Icons';
      // ctx.fillText('home_icon', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);

      ctx.save();
    }
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>

        
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              {props.t('dashboard_page.weekly_goal')}
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="body2"
              align="center"
            >
              {props.t('dashboard_page.totally').toUpperCase()}
            </Typography>
          </Grid>          
          <Grid item lg={12} sm={12} xl={12} xs={12} sx={{ height: '30vh' }}>
              <Doughnut
                data={weekly_visits_combined_data}
                options={options}
                plugins= {[textCenter]}
              />        
          </Grid>



            <Grid item lg={4} sm={4} xl={4} xs={4} sx={{ height: '14vh' }}>
              {/* <Avatar className={classes.avatar}>
                <HomeIcon className={classes.icon}/>
              </Avatar> */}
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="body2"
                align="center"
              >
                {props.t('common.home').toUpperCase()}
              </Typography>              
                <Doughnut
                  data={weekly_home_visits_data}
                  options={options}
                  plugins= {[textCenter]}
                />        
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4} ></Grid>
            <Grid  item lg={4} sm={4} xl={4} xs={4} sx={{ height: '14vh' }}>
            <Typography
                className={classes.title}
                color="textSecondary"
                variant="body2"
                align="center"
              >
                {props.t('common.gym').toUpperCase()}
              </Typography>               
                <Doughnut
                  data={weekly_gym_visit_data}
                  options={options}
                  plugins= {[textCenter]}
                />        
            </Grid>                
              {/* <Typography variant="h3">{props.weekly_goal_data.percent}%</Typography> */}

        </Grid>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom="false"
              variant="body2"
              align='center'
            >
              {props.t('dashboard_page.days_till_weekend', {count: daysUntilWeekend})}
            </Typography>
        <LinearProgress
          className={classes.progress}
          value={ Number( (7- daysUntilWeekend)*100/7)  }
          variant="determinate"
        />
      </CardContent>
    </Card>
  );
};


GoalProgress.propTypes = {
  className: PropTypes.string,
  weekly_goal_data: PropTypes.object,

};

export {
  GoalProgress,
  GoalWeeklyProgress
};

export default withTranslation()(GoalWeeklyProgress);
