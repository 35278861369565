export interface Membership {
	id: number;
	facility: number;
}

export interface Goals {
	id: number;
	gym_visits_per_week: number;
	gym_visits_per_month: number;
	home_training_sessions_per_week: number;
	home_training_sessions_per_month: number;
}

export interface Card {
	id: number;
	rfid_number: string;
	card_type: number;
	expiry_date: any;
}

export interface Profile {
	id: number;
	mugshot: string;
	privacy: number;
	language: any;
	welcome_name: string;
	date_of_birth: any;
	custom_id: any;
	comments: any;
	tags: any[];

}

export interface User {
	id: number;
	first_name: string;
	last_name: string;

	profile: Profile;
	membership: Membership;
	goals: Goals;
	cards: Card[];
}

export enum UserActions {
	SET_USER = "SET_USER",
}

interface UserActionType<T, P> {
	type: T;
	payload: P;
}

export type UserAction =
	| UserActionType<typeof UserActions.SET_USER, User>;
