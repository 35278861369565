
import React from 'react'
import { TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapForm : {
        display: "flex",
        justifyContent: "center",
        width: "95%",
        margin: `${theme.spacing(0)} auto`
    },
    wrapText  : {
        width: "100%"
    },
    button: {
        //margin: theme.spacing(1),
    },
  })
);


export const TextInput = (props) => {
    const classes = useStyles();
    
    // create state for text input
    const [message, setMessage] = React.useState(""); 

    // create state for button
    

    return (
        <>
            <form className={classes.wrapForm}  noValidate autoComplete="off">
            <TextField
                id="standard-text"
                label={props.label}
                className={classes.wrapText}
                value={message}
                onChange={(newValue) => setMessage(newValue.target.value)}
            />
            <Button variant="contained" color="primary" className={classes.button} onClick={() => props.writeReply(message)}>
                <SendIcon />
            </Button>
            </form>
        </>
    )
}



TextInput.propTypes = {
    writeReply: PropTypes.func,
    label: PropTypes.string,
  };
  
