import {
	Button,
	FormControl,
	InputLabel,
	Link,
	MenuItem,
	Select,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Axios from "axios";
import useAxios from "axios-hooks";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useActions } from "../actions";
import * as UserActions from "../actions/auth";
import logo from "../resources/img/for_lifelong.png";
import { getAccount } from "../services/api";
import authenticator from "../services/authenticator";
import { useTranslation } from 'react-i18next';
import InstallPWAButton from "../components/InstallPWAButton";
import { set } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://myhurgym.com/">
				My Hur Gym (0.2.0b)
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
		
	);
}

export function LoginPage(props) {
	const authActions = useActions(UserActions);

	const classes = useStyles();
	const onLoginRedirectUrl = "/app/dashboard";

	const [, setLoaded] = useState(false);
	const [server, setServer] = useState("");
	const [loggedIn, setLoggedIn] = useState(false);
	const [, setError] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [accessingGym, setAccessingGym] = useState(false);
	//const [urlMatchedHost, setUrlMatchedHost] = useState(null);
	const { t, i18n } = useTranslation();
	const changeLanguage = lng => {
	  i18n.changeLanguage(lng);
	};

	const [{ data, loading }] = useAxios(
		"https://myhurgym-public.s3.eu-north-1.amazonaws.com/hur_smarttouch_hosts_public.json"
	);
	
	const servers = [];
	const hosts = [];
	let urlMatchedHost = null;
	if (loading === false) {
		// get part of url to match
		let currentRelevantUrl = window.location.href.split('/')[2].split(':')[0];
		for (let i = 0; i < data.hosts.length; i += 1) {
			const host = data.hosts[i];
			servers.push(
				<MenuItem key={host.uuid} value={host.uuid}>
					{host.name}
				</MenuItem>
			);
			hosts.push(host);
			// check if url matches with one from the list
			if ("myhurgym_address" in host && host.myhurgym_address == currentRelevantUrl){
				urlMatchedHost = {...host};
				
			}
		}
		 
	}

	useEffect(() => {
		const isLoggedIn = authenticator.isLoggedIn();
		if (isLoggedIn) {
			setLoaded(true);
			setLoggedIn(true);
		} else {
			setLoaded(true);
		}
	}, []);

	const handleSubmit = async (e) => {
		if (e && e.preventDefault) e.preventDefault();
		const loginData = new FormData(e.target);
		const username = loginData.get("username");
		const password = loginData.get("password");

		let server_uuid = server; // from state
		let server_name = null;
		if (urlMatchedHost != null && "uuid" in urlMatchedHost){
			server_uuid = urlMatchedHost.uuid;
			server_name = urlMatchedHost.name;
		}

		if (!server_uuid) {
			setError("password");
			setErrorMsg("Please select server");
		} else {
			setError("");
			setErrorMsg("");
			setAccessingGym(true);
			try {
				const res = await Axios.post(
					"https://yg73pjd0x8.execute-api.eu-north-1.amazonaws.com/dev/login",
					{
						username,
						password,
						uuid: server_uuid,
					}
				);
				localStorage.setItem("secretKey", "allowmein");
				localStorage.setItem("access_token", res.data.access_token);
				localStorage.setItem("refresh_token", res.data.refresh_token);
				localStorage.setItem("server_url", res.data.url);
				localStorage.setItem("server_uuid", server_uuid);
				localStorage.setItem("server_name", server_name);
				setLoggedIn(true);

				const user = await getAccount();
				authActions.setUser(user);
			} catch (err) {
				setErrorMsg(
					get(
						err,
						"response.data.error_description",
						"Unable to login"
					)
				);
				setAccessingGym(false);
			}
		}
	};

	const handleChange = (event) => {
		setServer(event.target.value);
	};

	if (loggedIn) {
		console.log(onLoginRedirectUrl);
		return <Navigate replace={true} to={onLoginRedirectUrl} />;
	}
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<div>
					<img src={logo} alt="Logo" />
				</div>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
				{t('login_page.title')}
				</Typography>

				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit}
				>
					{loading && <p>Loading...</p>}
					{!loading && urlMatchedHost == null && (
						<FormControl required fullWidth variant="outlined">
							<InputLabel id="demo-simple-select-outlined-label">
								Server
							</InputLabel>
							<Select
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								value={server}
								onChange={handleChange}
								label="Server"
							>
								{servers}
							</Select>
						</FormControl>
					)}
					{!loading && urlMatchedHost != null && 'name' in urlMatchedHost && (
						<Typography component="h1" variant="h6">
								{urlMatchedHost.name}
						</Typography>
					)}
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						{t('login_page.sign_in_button')}
					</Button>
					<div>
						{errorMsg}
						{accessingGym && ( <span>Accessing gym server...</span>  )}
					</div>
				</form>
			</div>
			<Box  mt={4}>

				<Typography variant="body2" color="textSecondary" align="center">
						<button className="btn" onClick={() => changeLanguage('en')}>english</button>
						<button className="btn" onClick={() => changeLanguage('de')}>german</button>
						<button className="btn" onClick={() => changeLanguage('fi')}>finnish</button>
						<button className="btn" onClick={() => changeLanguage('fr')}>french</button><br/>
						<button className="btn" onClick={() => changeLanguage('zh_CN')}>chinese simplified</button>
						<button className="btn" onClick={() => changeLanguage('zh_TW')}>chinese</button>

				</Typography>

			</Box>
			<Box mt={4}>
				<Typography variant="body2" color="textSecondary" align="center">
					<InstallPWAButton />
				</Typography>
				<Copyright />
			</Box>
		</Container>
	);
}
