import { red, teal } from "@mui/material/colors";
import { deepOrange } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import WriteIcon from "@mui/icons-material/Create";
import InboxIcon from '@mui/icons-material/Inbox';
import OutBox from '@mui/icons-material/Outbox';

//import HomeIcon from "@mui/icons-material/Home";
//import { blue, green, grey, red } from "@mui/material/colors";

import get from "lodash/get";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../components";
import StartActivityModal from "../components/StartActivityModal";
import { getMessages } from "../services/api";
import { RootState } from "../reducers";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";


import WeeklyGoal from "../components/Settings/WeeklyGoal";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	topCard: {
		marginTop: 10,
		marginBottom: 20,
		padding: 10,
	},
	root: {
		padding: theme.spacing(1),
		/* textAlign: "center", */
		/*color: theme.palette.text.secondary,*/
		width: "100%",
	},
	fab: {
		zIndex: 100,
		position: "fixed",
		bottom: theme.spacing(10),
		right: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: teal[600],
		"&:hover": {
			backgroundColor: "#46a9db",
		},
	},
	stat_card: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
		width: "100%",
		overflow: "visible",	
	},
	stat_card_icon: {
		marginTop: -35,
		marginBottom: 20,
		padding: 0,
		zIndex: 100,
	},
	inline: {
		display: 'inline',
	},
	sent_message_avatar: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500]
	},
	received_message_avatar: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500]

	},
	inbox_outbox_button: {
		marginRight: 0,
		marginLeft: 0,
		padding: 0,
		minWidth: 0,
	},
    messageTimeStampRight: {
		position: "absolute",
		fontSize: ".75em",
		fontWeight: "300",
		marginTop: "10px",
		bottom: "-3px",
		right: "5px"
	  },
	  orange: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500],
		width: theme.spacing(4),
		height: theme.spacing(4)
	  }


}));

export function MessagesPage() {
	const navigate = useNavigate();	
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [messages, setMessages] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [inboxActive, setInboxActive] = React.useState(true);

	const currentUser = useSelector((state: RootState) => state.user);

	// set language
	moment.locale(i18n.language);

	const handleClose = () => {
		setOpen(false);
	};

	const write_message = () => {
		navigate("/app/message/write");
	};
	
	useEffect(() => {
		async function initData() {
			setLoading(true);
			try {
				const data = await getMessages();
				setMessages(data);
				console.log(data);

			} catch (e) {
				console.log(e);
			}
			setLoading(false);
		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	if (loading) {
		return <Loading />;
	}


	let messages_list = [];	
	messages_list = messages.results.map((msg, idx: number) => {
		return(
		<div>
		{/* Inbox */}
		{msg.recipient == currentUser.id && inboxActive &&
		<div>
			<ListItem alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={msg.sender_fullname} src={msg.sender_mugshot ? msg.sender_mugshot : "#"} className={classes.received_message_avatar}/> 
			</ListItemAvatar>
			<ListItemText
				// make it clickable
				onClick={() => {navigate(`/app/message/${msg.id}/?t=${msg.thread}`);}}
				//make it bold if it is not read yet 
				primary={msg.read_at ? msg.sender_fullname : <b>{msg.sender_fullname}</b>}

				secondary={
				<React.Fragment>
					<Typography
					component="span"
					variant="body2"
					className={classes.inline}
					color="textPrimary"
					>
					{msg.subject}
					</Typography>
					&nbsp;—&nbsp;{msg.body}
				</React.Fragment>
				}
			/>
			<div className={classes.messageTimeStampRight}>{moment(msg.sent_at).format('llll')}</div>
			</ListItem>
			<Divider variant="inset" component="li" />
		</div>
		}
		{/* Outbox */}
		{msg.sender == currentUser.id && !inboxActive &&
		<div>
			<ListItem alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={msg.recipient_fullname} src={msg.recipient_mugshot ? msg.recipient_mugshot : "#"} className={classes.sent_message_avatar}/> 
			</ListItemAvatar>
			<ListItemText
				// make it clickable
				onClick={() => {navigate(`/app/message/${msg.id}/?t=${msg.thread}`);}}
				//make it bold if it is not read yet 
				primary={msg.read_at ? msg.recipient_fullname : <b>{msg.recipient_fullname}</b>}

				secondary={
				<React.Fragment>
					<Typography
					component="span"
					variant="body2"
					className={classes.inline}
					color="textPrimary"
					>
					{msg.subject}
					</Typography>
					&nbsp;—&nbsp;{msg.body}
				</React.Fragment>
				}
			/>
			<div className={classes.messageTimeStampRight}>{moment(msg.sent_at).format('llll')}</div>
			</ListItem>
			<Divider variant="inset" component="li" />
		</div>
		}

		</div>
		)		
	});
	console.log("messages_list", messages);

    const current_month = moment().format('MMMM');
	return (
		<div className={classes.root}>
						<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12} textAlign={"right"}>
					<Button variant="text" color="inherit" className={classes.inbox_outbox_button} onClick={() => { setInboxActive(true); console.log("Inbox");}}>
					 	<InboxIcon  fontSize="small" htmlColor={inboxActive ? "blue": "grey"}/>
					</Button>
					<Button variant="text" color="inherit" className={classes.inbox_outbox_button} onClick={() => { setInboxActive(false); console.log("Outbox");}}>
						<OutBox fontSize="small" htmlColor={inboxActive ? "grey": "blue"}/>
					</Button>
				</Grid>
			</Grid>

			{messages_list.length > 0 && 
				<List className={classes.root}>
					{messages_list}
				</List>			
			}
			{messages_list.length == 0 && 
				<Typography component="span" variant="body2" className={classes.root}>
					{ t('messages_page.no_messages') }...
				</Typography>			
			}			
			<Fab
				variant="extended"
				color="primary"
				aria-label="add"
				className={classes.fab}
				onClick={write_message}
			>
				<WriteIcon/>
				{ t('messages_page.write_message') }
			</Fab>
			{/* {open ? (
				<StartActivityModal open={open} handleClose={handleClose} />
			) : (
				<Fragment />
			)} */}
		</div>
	);
}
